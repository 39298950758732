import { For, Show } from "solid-js";
import { HubbleEvent, webEventManager } from "~/data/events";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import { VoucherRedemptionType } from "~/server/types/order";
import { showSnackBar } from "~/shared_states/snackbar";
import { Divider } from "~/widgets/divider";
import { Spacer } from "~/widgets/spacer";

export type GeneratedVoucherDetailsProps = {
  isPreview: boolean;
  brand: BrandDetailResponse;
  voucher: VoucherDetailResponse;
  defaultEventProps?: Record<string, any>;
};

export function GeneratedVoucherDetails(props: GeneratedVoucherDetailsProps) {
  return (
    <>
      <Show
        when={props.brand.deeplink}
        fallback={<Divider bgColor="#7C7C7C" />}
      >
        <AddToBrandCta />
      </Show>
      <Spacer height={18} />
      <div class="w-full">
        <CardDetail
          voucherRedemptionType={props.voucher.redemptionType}
          cardPin={props.voucher.pin ?? ""}
          cardNumber={props.voucher.cardNumber ?? ""}
          defaultEventProps={props.defaultEventProps}
        />
      </div>
    </>
  );

  function AddToBrandCta() {
    return (
      <div
        onClick={async () => {
          webEventManager.sendEvent(HubbleEvent.GIFT_CLICK_ADD_TO_BRAND, {
            ...props.defaultEventProps,
          });

          if (props.isPreview) {
            showSnackBar({
              message: "Brand's website/application will open",
              level: "success",
            });
            return;
          }

          if (props.voucher.redemptionType === "PIN_NO_SECURED") {
            await navigator.clipboard.writeText(props.voucher.pin!);
            showSnackBar({
              message: "Copied pin to clipboard",
              level: "success",
            });
          } else {
            await navigator.clipboard.writeText(props.voucher.cardNumber!);
            showSnackBar({
              message: "Copied card number to clipboard",
              level: "success",
            });
          }
          await new Promise((resolve) => setTimeout(resolve, 500));

          const newTab = window.open(props.brand.deeplink!, "_blank");
          if (newTab) {
            newTab.focus();
          } else {
            console.error("Failed to open the new tab.");
          }
        }}
        class="flex w-full cursor-pointer flex-row items-center justify-center rounded-[8px] bg-[#FFFFFF] px-4 py-[14px] text-[14px] font-bold text-basePrimaryDark"
      >
        <p>Add to {props.brand.brandName}</p>
        <Spacer width={4} />
        <i class="ph-bold ph-arrow-up-right"></i>{" "}
      </div>
    );
  }
}

function CardDetail(props: {
  voucherRedemptionType: VoucherRedemptionType;
  cardNumber: string;
  cardPin: string;
  defaultEventProps?: Record<string, any>;
}) {
  type CardProp = {
    label: string;
    value: string;
    display: string;
    snackbarLabel: string;
  };

  const cardProps: CardProp[] = [];

  switch (props.voucherRedemptionType) {
    case "CARD_AND_PIN_NO_SECURED":
      cardProps.push({
        label: "CARD NO",
        value: props.cardNumber,
        display: formatCardNumber(props.cardNumber),
        snackbarLabel: "Card no.",
      });
      cardProps.push({
        label: "PIN",
        value: props.cardPin,
        display: props.cardPin,
        snackbarLabel: "Card pin",
      });
      break;

    case "CARD_NUMBER_SECURED":
      cardProps.push({
        label: "CARD NO",
        value: props.cardNumber,
        display: formatCardNumber(props.cardNumber),
        snackbarLabel: "Card no.",
      });
      break;
    case "PIN_NO_SECURED":
      cardProps.push({
        label: "PIN",
        value: props.cardPin,
        display: props.cardPin,
        snackbarLabel: "Card pin",
      });
      break;
    default:
      if (props.cardNumber) {
        cardProps.push({
          label: "CARD NO",
          value: props.cardNumber,
          display: formatCardNumber(props.cardNumber),
          snackbarLabel: "Card no.",
        });
      }
      if (props.cardPin) {
        cardProps.push({
          label: "PIN",
          value: props.cardPin,
          display: props.cardPin,
          snackbarLabel: "Card pin",
        });
      }
      break;
  }

  let elements = cardProps.map((props) => {
    return <CardProperty {...props} />;
  });

  if (elements.length == 2) {
    elements = [
      elements[0],
      <Spacer height={10} />,
      <Divider bgColor="#333232" />,
      <Spacer height={10} />,
      elements[1],
    ];
  }

  return (
    <For each={elements}>
      {(element) => {
        return element;
      }}
    </For>
  );

  function CardProperty(cardPropertyProps: CardProp) {
    return (
      <div
        onClick={async () => {
          webEventManager.sendEvent(HubbleEvent.GIFT_CLICK_COPY, {
            ...props.defaultEventProps,
            property: cardPropertyProps.label,
          });
          await navigator.clipboard.writeText(cardPropertyProps.value);
          showSnackBar({
            message: `${cardPropertyProps.snackbarLabel} copied to clipboard`,
            level: "success",
          });
        }}
        class="flex w-full cursor-pointer flex-row items-center justify-between text-[10px]  font-medium tracking-[1.8px] text-baseSecondaryMedium "
      >
        <div>{cardPropertyProps.label}:</div>
        <div class="flex flex-row items-center">
          <div>{cardPropertyProps.display}</div>
          <Spacer width={10} />
          <CopyIcon />
        </div>
      </div>
    );
  }
}
function formatCardNumber(cardNumber: string) {
  return cardNumber.replace(/(.{4})/g, "$1 ");
}
function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="5.83154"
        y="5.8316"
        width="11.6715"
        height="11.6715"
        rx="2"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83155 14.1684H4.16419C3.24333 14.1684 2.49683 13.4219 2.49683 12.5011V4.16425C2.49683 3.24339 3.24333 2.49689 4.16419 2.49689H12.501C13.4219 2.49689 14.1684 3.24339 14.1684 4.16425V5.83161"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
